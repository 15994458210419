import React from 'react';
import {
  Box,
  Table,
  TableCellProps,
  Tbody,
  Td as ChakraTd,
  Text,
  Th as ChakraTh,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { DumbPagination } from '@components/common';

export const EmptyTable = (): JSX.Element => {
  return (
    <>
      <TableWrapper>
        <Tr>
          <Td colSpan={4} withBorder={true} borderBottomWidth={1}>
            <Text
              textStyle='sm-normal'
              textAlign='center'
              color='blackAlpha.700'
            >
              No organizations to display
            </Text>
          </Td>
        </Tr>
      </TableWrapper>
      <DumbPagination currentPage={1} itemsThisPage={0} hasNextPage={false} />
    </>
  );
};

type ThProps = {
  children?: React.ReactNode;
} & TableCellProps;

const Th = ({ children, ...rest }: ThProps): JSX.Element => (
  <ChakraTh
    borderColor='gray.200'
    color='gray.500'
    textStyle='sm-normal'
    {...rest}
  >
    {children}
  </ChakraTh>
);

type TdProps = {
  children: React.ReactNode;
  withBorder?: boolean;
} & TableCellProps;

export const Td = ({
  children,
  withBorder = false,
  ...rest
}: TdProps): JSX.Element => {
  const borderStyle = withBorder
    ? { borderColor: 'gray.200' }
    : { border: 'none' };

  return (
    <ChakraTd {...borderStyle} {...rest}>
      {children}
    </ChakraTd>
  );
};

type TableWrapperProps = {
  children: React.ReactNode;
};

export const TableWrapper = ({ children }: TableWrapperProps): JSX.Element => {
  return (
    <Box overflowX='auto'>
      <Table>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>ID</Th>
            <Th>Members</Th>
            <Th>Created</Th>
          </Tr>
        </Thead>

        <Tbody>{children}</Tbody>
      </Table>
    </Box>
  );
};
