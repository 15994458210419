import React from 'react';
import { useInstance } from '@hooks';

import { OrganizationsEnablePrompt } from '@components/organizations';
import { Center, Spinner, VStack, Box, HStack } from '@chakra-ui/react';
import { Filters, CreateOrganizationModal } from './core';
import { OrganizationTable } from './OrganizationTable';

import { useFeatureFlags } from '@hooks';

export function OrganizationList(): JSX.Element {
  const { instance } = useInstance();
  const { create_organization_dashboard } = useFeatureFlags();

  const isEnabled = instance?.organization_settings.enabled;
  return (
    <>
      {!instance ? (
        <Center w='full' h='full'>
          <Spinner aria-label='Loading...' />
        </Center>
      ) : (
        <>
          {isEnabled ? (
            <VStack
              alignItems='self-start'
              spacing={2}
              width='100%'
              flexDirection='column'
            >
              <HStack justifyContent='space-between' width='100%'>
                <Filters />
                {create_organization_dashboard && <CreateOrganizationModal />}
              </HStack>
              <Box width='100%'>
                <OrganizationTable instanceId={instance.id} />
              </Box>
            </VStack>
          ) : (
            <OrganizationsEnablePrompt />
          )}
        </>
      )}
    </>
  );
}
