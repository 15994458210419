import React from 'react';

import { CardDualPanel, InputBox } from '@components/common';

import { ConfirmationModal } from '@components/modal';
import {
  Button,
  useDisclosure,
  Flex,
  Text,
  InputGroup,
  InputRightAddon,
  InputLeftAddon,
  Input,
} from '@chakra-ui/react';

import { useDashboardCRUD, useToast } from '@hooks';

import { ExclamationIcon } from '@heroicons/react/solid';
import { useTLDParser } from '@hooks/useTLDParser';

const ChangeSubDomainBody = ({ domain, subdomain, setSubdomain, error }) => {
  return (
    <Flex direction='column' gap={4}>
      <Text textStyle='md-normal'>
        Specify the subdomain where your main application lives. This will be
        used as the base for your custom redirects.
      </Text>
      <Flex direction='column' gap={1}>
        <InputBox error={error} label='Subdomain' m={0} p={0}>
          <InputGroup>
            <InputLeftAddon color='rgba(0, 0, 0, 0.65)'>
              https://
            </InputLeftAddon>

            <Input
              textAlign='right'
              value={subdomain}
              onChange={e => setSubdomain(e.target.value)}
            />
            <InputRightAddon color='rgba(0, 0, 0, 0.65)'>
              .{domain}
            </InputRightAddon>
          </InputGroup>
        </InputBox>
      </Flex>
    </Flex>
  );
};

const ChangeSubdomain = ({
  domain,
  instanceId,
  deployedOn,
  onSubdomainChange,
}: {
  domain: string;
  instanceId: string;
  deployedOn: string;
  onSubdomainChange: () => void;
}): JSX.Element => {
  const { isOpen, onClose, getButtonProps } = useDisclosure();
  const { showErrorToast, showSuccessToast } = useToast();
  const [subdomain, setSubdomain] = React.useState('');
  const { create } = useDashboardCRUD();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState(null);
  const { parse } = useTLDParser();

  async function handleChangeSubdomain() {
    try {
      const newDomain = `https://${subdomain}.${domain}`;
      const tldResult = parse(newDomain);
      if (!tldResult.subdomain) {
        setError({ message: 'The subdomain is not valid' });
        return;
      }
      setIsSubmitting(true);
      await create(`/v1/instances/${instanceId}/change_domain`, {
        home_url: newDomain,
      });
      showSuccessToast(`Subdomain has been updated to ${newDomain}`);
      onSubdomainChange();
      handleClose();
    } catch (err) {
      if (err?.fieldErrors && err?.fieldErrors.length > 0) {
        setError({ message: err?.fieldErrors[0].long_message });
      } else if (err?.globalErrors && err?.globalErrors.length > 0) {
        setError({ message: err?.globalErrors[0].long_message });
      } else {
        showErrorToast('Could not update subdomain');
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  const handleClose = () => {
    setError(null);
    setSubdomain('');
    onClose();
  };

  return (
    <CardDualPanel
      alignItems='center'
      rightPanelStyles={{ justifyContent: 'space-between' }}
      title='Change subdomain'
      subtitle={`Currently deployed on ${deployedOn}`}
    >
      <Button
        fontWeight='bold'
        fontSize='xs'
        ml='auto'
        {...getButtonProps()}
        colorScheme='red'
      >
        Change subdomain
      </Button>
      <ConfirmationModal
        disableConfirmation={subdomain === ''}
        isConfirmationLoading={isSubmitting}
        alertDialogProps={{ width: '30rem' }}
        isOpen={isOpen}
        handleCancelation={handleClose}
        handleConfirmation={handleChangeSubdomain}
        confirmationMessage='Change subdomain'
        cancelationMessage='Cancel'
        confirmButtonIcon={ExclamationIcon}
        message={
          <ChangeSubDomainBody
            error={error}
            domain={domain}
            subdomain={subdomain}
            setSubdomain={setSubdomain}
          />
        }
        headerTitle='Change subdomain'
      />
    </CardDualPanel>
  );
};

export default React.memo(ChangeSubdomain);
