import { TableCellProps } from '@chakra-ui/react';

export type BaseSchema<T> = Array<{
  header: string;
  cell: (data: T) => React.ReactNode;
  tdProps?: TableCellProps;
  key: string;
  enableSorting?: boolean;
}>;

export const TableSortingDirections = {
  DESC: 'DESC',
  ASC: 'ASC',
} as const;

export type TableSortingDirection = keyof typeof TableSortingDirections;

export type TableSortingData = {
  column: string;
  direction: TableSortingDirection;
};
