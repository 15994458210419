import React, { useState } from 'react';

import { DumbPagination } from '@components/common';
import { EmptyTable } from './TableParts';

import { usersSchema } from './schema';

import { BaseSchema, TableSortingData } from '@types';
import { User } from './types';

import { Table } from '@components/common';
import { TableSortingDirections } from '@types';

export type UsersTableProps<T> = {
  users: T[];
  page: number;
  perPage: number;
  totalCount?: number;
  rowActions: (data: T) => JSX.Element;
  onRowClicked: (data: T) => void;
  emptyTablePlaceholder?: React.ReactNode;
  tableSchema?: BaseSchema<T>;
  onPaginate?: (value: number) => void;
  onSorted?: (data: T) => void;
};

export function UsersTable<T extends { id: string } = User>({
  users = [],
  page,
  perPage,
  rowActions,
  onRowClicked,
  totalCount,
  tableSchema = usersSchema() as any,
  onPaginate,
  emptyTablePlaceholder,
  onSorted,
}: UsersTableProps<T>): JSX.Element {
  const [sortingState, setSortingState] = useState<TableSortingData>({
    column: 'name',
    direction: TableSortingDirections.DESC,
  });

  const hasNextPage = React.useMemo(() => {
    if (!totalCount) {
      return false;
    }

    return totalCount > perPage * page;
  }, [page, perPage, totalCount]);

  const handleSort = data => {
    setSortingState({
      column: data.column,
      direction: data.direction,
    });

    return onSorted(data);
  };

  return (
    <>
      <Table
        emptyTableFallback={
          <EmptyTable emptyTablePlaceholder={emptyTablePlaceholder} />
        }
        tableSchema={tableSchema}
        data={users}
        rowActions={rowActions}
        onRowClicked={onRowClicked}
        onSorted={handleSort}
        sortingState={sortingState}
      />

      <DumbPagination
        currentPage={page}
        itemsThisPage={users.length}
        hasNextPage={hasNextPage}
        onPaginate={onPaginate}
      />
    </>
  );
}
