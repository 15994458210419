import { useLocation } from '@hooks';
import { useSession, useOrganization } from '@clerk/nextjs';
import { makeOrganizationMembershipsPath } from '@components/organizations/utils';
import { buildCacheKey } from '@utils/api';

import { PAGE_SIZE } from '@components/organizations';

const useMembershipsPathAndKeys = ({
  organizationID,
}: {
  organizationID?: string;
} = {}): {
  cacheKey: string;
  path: string;
  pathWithParams: string;
} => {
  const { instanceId, query } = useLocation();
  const { session } = useSession();
  const { organization } = useOrganization();
  const { organizationIDorSlug } = query;

  const path = makeOrganizationMembershipsPath(
    instanceId,
    organizationID || (organizationIDorSlug as string),
  );

  const search = (query?.query as string) || '';
  const page = parseInt(query.members_page as string, 10) || 1;
  const role = (query.role as string) || '';

  const params = new URLSearchParams({
    query: search,
    offset: PAGE_SIZE * (page - 1) + '',
  });

  if (role && Array.isArray(role)) {
    role.forEach(currentRole => {
      params.append('role', currentRole);
    });
  } else if (role) {
    params.append('role', role);
  }

  const pathWithParams = `${path}?${params.toString()}`;

  const cacheKey = buildCacheKey({
    path: pathWithParams,
    session,
    id: organization?.id,
  });

  return { cacheKey, pathWithParams, path };
};

export default useMembershipsPathAndKeys;
