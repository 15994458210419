import React from 'react';

import { useLocation } from '@hooks';
import { useRouter } from 'next/router';

import { membershipSchema, MemberShipSchema } from './schema';

import {
  useGetMemberships,
  useMembershipsPathAndKeys,
} from '@components/organizations/memberships';
import { OrganizationMembershipFLatUserData } from '@components/organizations/types';

const makeMembershpSchema = membershipSchema();

type UsersTableReturnType = {
  data: OrganizationMembershipFLatUserData[];
  schema: MemberShipSchema;
  totalCount: number;
  page: number;
  query: string;
  path: string;
  isLoading: boolean;
  onRowClicked: (member: OrganizationMembershipFLatUserData) => void;
  onPaginate: (value: number) => void;
};
const useTable = (): UsersTableReturnType => {
  const { instanceId, applicationId, query } = useLocation();
  const router = useRouter();
  const { path } = useMembershipsPathAndKeys();
  const { organizationIDorSlug } = query;

  const { memberships, hasLoaded, totalCount } = useGetMemberships({
    organizationID: organizationIDorSlug as string,
  });

  const page = parseInt(router.query.members_page as string, 10) || 1;
  const userProfilePath = `/apps/${applicationId}/instances/${instanceId}/users`;

  const onRowClicked = React.useCallback(
    (member: OrganizationMembershipFLatUserData) => {
      void router.push({
        pathname: `${userProfilePath}/${member.user_id}`,
        query: {
          members_page: page,
        },
      });
    },
    [page, userProfilePath, router],
  );

  const onPaginate = (value: number) => {
    void router.push({ query: { ...router.query, members_page: value } });
  };

  return {
    data: memberships,
    isLoading: !hasLoaded,
    schema: makeMembershpSchema,
    page,
    query: (router.query.query as string) || '',
    onRowClicked,
    path,
    totalCount,
    onPaginate,
  };
};

export default useTable;
