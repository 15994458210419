/* Identifier to reduce a bit of storage and make identification more type safe */
export enum TodoIds {
  Documentation = 'dc',
  UserManagement = 'um',
  Theme = 'th',
  OAuth = 'oa',
  ApiKeys = 'ap',
  DNS = 'dn',
}

export interface Todo {
  link: string;
  linkText: string;
  title: string;
  content: string | React.ReactNode;
  completed: boolean;
  id: TodoIds;
  dismissed: boolean;
  target: '_blank' | '_self' | ' _parent' | '_top';
}

/** Used to map change in state coming from the user actions */
export type TodoInteractionState = {
  [key in TodoIds]?: Pick<Todo, 'completed' | 'dismissed'>;
};

/** Todos state stored in user metadata, after bitmasking */
export type StoredTodos = { [key in TodoIds]?: number };
