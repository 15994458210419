import React from 'react';

import { UserAvatar } from '@components/common';
import { Box, Text, HStack } from '@chakra-ui/react';

import { OrganizationMembershipFLatUserData } from '@components/organizations/types';

type NameCellProps = {
  member: Pick<
    OrganizationMembershipFLatUserData,
    'first_name' | 'last_name' | 'identifier' | 'profile_image_url' | 'user_id'
  >;
};
const NameCell = ({ member }: NameCellProps): JSX.Element => {
  return (
    <HStack>
      <UserAvatar user={member} boxSize={8} />;
      <Box>
        <Text noOfLines={1} textStyle='sm-medium'>
          {member.first_name} {member.last_name}
        </Text>
        <Text noOfLines={1} mt='0px' textStyle='sm-normal' color='gray.500'>
          {member.identifier || member.user_id}
        </Text>
      </Box>
      ;
    </HStack>
  );
};

export default NameCell;
