import React from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useDashboardCRUD } from '@hooks/useDashboardAPI';
import { useLocation } from '@hooks';
import type { InstanceKey } from '@types';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { removeAutoComplete } from '@utils';
import { CopyTextInput, Modal } from '../common';

type CreateSecretKeyModalProps = {
  isOpen: boolean;
  onClose: ({ revalidateKeys: boolean }) => void;
};

enum ModalSteps {
  CreateSecretKey = 'CreateSecretKey',
  ViewInstanceKey = 'ViewInstanceKey',
}

const modalContent = {
  [ModalSteps.CreateSecretKey]: {
    closeCta: 'Cancel',
    closeVariant: 'ghost',
  },
  [ModalSteps.ViewInstanceKey]: {
    closeCta: 'Close',
    closeVariant: 'solid',
  },
};

type CreateSecretKeyForm = {
  keyName: string;
};

const FORM_ID = 'create-instance-key-form';
const INPUT_ID = 'keyName';

export function CreateSecretKeyModal({
  isOpen,
  onClose,
}: CreateSecretKeyModalProps): JSX.Element {
  const { instanceId } = useLocation();
  const [modalStep, setModalStep] = React.useState<ModalSteps>(
    ModalSteps.CreateSecretKey,
  );
  const {
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<CreateSecretKeyForm>();

  const { create } = useDashboardCRUD<InstanceKey>();
  const [instanceKey, setInstanceKey] = React.useState<InstanceKey>();

  const createNewInstanceKey = async ({ keyName }: CreateSecretKeyForm) => {
    const newInstanceKey = await create(
      `/v1/instances/${instanceId}/instance_keys`,
      { name: keyName },
    );
    setInstanceKey(newInstanceKey);
    setModalStep(ModalSteps.ViewInstanceKey);
  };

  const handleCancel = () => {
    reset();
    setModalStep(ModalSteps.CreateSecretKey);
    setInstanceKey(undefined);
    onClose({ revalidateKeys: modalStep === ModalSteps.ViewInstanceKey });
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCancel} title='Create secret key'>
      <Modal.Body>
        {modalStep === ModalSteps.CreateSecretKey ? (
          <form id={FORM_ID} onSubmit={handleSubmit(createNewInstanceKey)}>
            <FormControl
              isInvalid={!!errors[INPUT_ID]}
              {...removeAutoComplete(INPUT_ID)}
            >
              <FormLabel fontSize='sm'>Name</FormLabel>

              <Input
                type='text'
                {...register(INPUT_ID, {
                  required: 'Name is required',
                })}
              />

              <FormErrorMessage>{errors[INPUT_ID]?.message}</FormErrorMessage>
            </FormControl>
          </form>
        ) : (
          <CopyTextInput
            p={0}
            label={instanceKey.name}
            valueToCopy={instanceKey.secret}
            helpText={
              <FormHelperText as={Flex}>
                <Icon as={InformationCircleIcon} mt='1px' mr='1' />
                <Box>
                  Set this key as the CLERK_SECRET_KEY environment variable for
                  Clerk Backend API SDKs
                </Box>
              </FormHelperText>
            }
          />
        )}
      </Modal.Body>

      <Modal.Footer flexDir='row-reverse'>
        {modalStep === ModalSteps.CreateSecretKey ? (
          <Button type='submit' form={FORM_ID} isLoading={isSubmitting}>
            CONTINUE
          </Button>
        ) : null}

        <Button
          variant={modalContent[modalStep].closeVariant}
          px='6'
          onClick={handleCancel}
        >
          {modalContent[modalStep].closeCta}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
