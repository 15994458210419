import React from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { AppProps } from 'next/app';
import PlausibleProvider from 'next-plausible';
import {
  ClerkLoaded,
  ClerkProvider,
  MultisessionAppSupport,
  RedirectToSignIn,
  SignedIn,
  SignedOut,
  useUser,
  withClerk,
  WithClerkProp,
} from '@clerk/nextjs';
import { ClientResource } from '@clerk/types';
import { GoogleTagManager } from '@components/googleTagManager';
import { Layout } from '@components/layouts';
import { NotificationsProvider } from '@context/NotificationsContext';
import { AnalyticsProvider } from '@components/segmentAnalytics';
import { useLastActive } from '@hooks';
import ClerkListener from '@components/listeners/ClerkListener';

import '../public/styles/main.scss';

import '@fontsource/source-code-pro/200.css';
import '@fontsource/source-code-pro/400.css';
import '@fontsource/source-code-pro/600.css';
import '@fontsource/source-code-pro/900.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';

const PUBLIC_PAGES = [
  '/vercel/install',
  '/sign-up/[[...index]]',
  '/sign-in/[[...index]]',
];

export const RedirectToHome = withClerk(({ clerk }: WithClerkProp) => {
  React.useEffect(() => {
    void clerk.redirectToHome();
  }, []);

  return null;
}, 'RedirectToHome');

function App({ Component, pageProps }: AppProps): JSX.Element {
  const router = useRouter();
  useLastActive({ shouldTrack: true });

  const sessionSelector = (client: ClientResource) => {
    const params = new URLSearchParams(window.location.search);
    const sessionId =
      params.get('clerk_session_id') || client.lastActiveSessionId;

    const lastActiveSession = client.activeSessions.find(
      s => s.id === sessionId,
    );

    return lastActiveSession || client.activeSessions[0] || null;
  };

  const frontendApi =
    typeof window !== 'undefined'
      ? window.location.host.replace('dashboard.', 'clerk.')
      : '';

  return (
    <AnalyticsProvider>
      <ClerkProvider
        frontendApi={frontendApi}
        selectInitialSession={sessionSelector}
        clerkJSUrl={process.env.NEXT_PUBLIC_CLERK_JS}
        polling={false}
        appearance={{
          variables: {
            colorPrimary: '#6c47ff',
          },

          elements: {
            organizationSwitcherTrigger: {
              padding: '0 12px 0 0',
              letterSpacing: 0,
              '*': {
                // this fix is needed until this issue is resolved
                // https://clerkinc.slack.com/archives/C046VRJPCNS/p1678804449100449?thread_ts=1678801346.320739&cid=C046VRJPCNS
                fontSize: '13px!important',
              },
            },
          },
        }}
      >
        <MultisessionAppSupport>
          <ClerkListener />
          <Head>
            <title>Dashboard | Clerk.com</title>
            <meta
              property='og:title'
              content='Dashboard | Clerk.com'
              key='title'
            />
            <meta
              name='viewport'
              content='initial-scale=1.0, width=device-width'
            />
          </Head>
          <GoogleTagManager>
            <NotificationsProvider>
              {PUBLIC_PAGES.includes(router.pathname) ? (
                <PlausibleProvider
                  domain={process.env.NEXT_PUBLIC_DASHBOARD_URL}
                >
                  <ClerkLoaded>
                    <Component {...pageProps} />
                  </ClerkLoaded>
                </PlausibleProvider>
              ) : (
                <>
                  <SignedIn>
                    <SignedInPaths {...pageProps}>
                      {() => <Component {...pageProps} />}
                    </SignedInPaths>
                  </SignedIn>
                  <SignedOut>
                    {/* This is a temporary fix during the clerk.com migration and
                    should be removed in a week from this commit. For more information refer to https://clerkinc.slack.com/archives/CHZ1FBBEG/p1678883546064099?thread_ts=1678882994.702419&cid=CHZ1FBBEG */}
                    {typeof window !== 'undefined' &&
                    window.location.hostname ===
                      process.env.NEXT_PUBLIC_DASHBOARD_URL ? (
                      <RedirectToSignIn
                        redirectUrl={
                          process.env.NEXT_PUBLIC_REDIRECT_TO_SIGN_IN_URL
                        }
                      />
                    ) : (
                      <RedirectToHome />
                    )}
                  </SignedOut>
                </>
              )}
            </NotificationsProvider>
          </GoogleTagManager>
        </MultisessionAppSupport>
      </ClerkProvider>
    </AnalyticsProvider>
  );
}

export default App;

function SignedInPaths({ children }): JSX.Element {
  const { user } = useUser();

  const isClerkMember = user.emailAddresses?.some(email =>
    email?.emailAddress?.endsWith('@clerk.dev'),
  );

  return (
    <PlausibleProvider
      domain={process.env.NEXT_PUBLIC_DASHBOARD_URL}
      enabled={!isClerkMember}
    >
      <Layout>{children()}</Layout>
    </PlausibleProvider>
  );
}
