export const JwtTemplateSessionTokenName = '__session';

export type JwtVendor =
  | 'blank'
  | 'google'
  | 'hasura'
  | 'fauna'
  | 'supabase'
  | 'grafbase'
  | 'nhost'
  | 'convex'
  | 'mongodb'
  | 'announcekit'
  | 'grafana';

export enum JwtSigningAlgorithm {
  'HS256' = 'HS256', // HMAC using SHA-256
  'HS384' = 'HS384', // HMAC using SHA-384
  'HS512' = 'HS512', // HMAC using SHA-512
  'RS256' = 'RS256', // RSASSA-PKCS-v1.5 using SHA-256
  'RS384' = 'RS384', // RSASSA-PKCS-v1.5 using SHA-384
  'RS512' = 'RS512', // RSASSA-PKCS-v1.5 using SHA-512
  'ES256' = 'ES256', // ECDSA using P-256 and SHA-256
  'ES384' = 'ES384', // ECDSA using P-384 and SHA-384
  'ES512' = 'ES512', // ECDSA using P-521 and SHA-512
}

export type JwtTemplate = {
  object: string;
  id: string;
  name: string;
  lifetime: number;
  allowed_clock_skew: number;
  claims: object;
  custom_signing_key: boolean;
  signing_algorithm: JwtSigningAlgorithm;
  updated_at: number;
  created_at: number;
};

export type JwtTemplateFormFields = {
  name: string;
  lifetime: number;
  allowed_clock_skew: number;
  claims: string;
  custom_signing_key: boolean;
  signing_algorithm: JwtSigningAlgorithm;
  signing_key: string;
};

export const JwtTemplateFormDefaults = {
  name: '',
  lifetime: 60,
  allowed_clock_skew: 5,
  claims: '{}',
  custom_signing_key: false,
  signing_algorithm: JwtSigningAlgorithm.RS256,
  signing_key: '',
} as JwtTemplateFormFields;

export type JwtTemplateStarter = {
  vendor: JwtVendor;
  name: string;
  label: string;
  docs_url?: string;
  claims: Record<string, unknown>;
  signing_algorithm: JwtSigningAlgorithm;
  custom_signing_key: boolean;
  enabled: boolean;
};

export const JwtTemplateStarterList: JwtTemplateStarter[] = [
  {
    vendor: 'blank',
    name: 'blank',
    label: 'Blank',
    claims: {},
    custom_signing_key: false,
    signing_algorithm: JwtSigningAlgorithm.RS256,
    enabled: true,
  },
  {
    vendor: 'google',
    name: 'gcloud',
    label: 'Google Cloud',
    docs_url:
      'https://cloud.google.com/api-gateway/docs/authenticating-users-jwt',
    claims: {
      aud: 'https://SERVICE_NAME',
    },
    custom_signing_key: true,
    signing_algorithm: JwtSigningAlgorithm.RS256,
    enabled: false,
  },
  {
    vendor: 'hasura',
    name: 'hasura',
    label: 'Hasura',
    docs_url: 'https://clerk.com/docs/integration/hasura',
    claims: {
      'https://hasura.io/jwt/claims': {
        'x-hasura-allowed-roles': ['user'],
        'x-hasura-default-role': 'user',
        'x-hasura-user-id': '{{user.id}}',
      },
    },
    custom_signing_key: false,
    signing_algorithm: JwtSigningAlgorithm.RS256,
    enabled: true,
  },
  {
    vendor: 'supabase',
    name: 'supabase',
    label: 'Supabase',
    docs_url: 'https://clerk.com/docs/integration/supabase',
    claims: {
      aud: 'authenticated',
      email: '{{user.primary_email_address}}',
      role: 'authenticated',
      app_metadata: {},
      user_metadata: {},
    },
    custom_signing_key: true,
    signing_algorithm: JwtSigningAlgorithm.HS256,
    enabled: true,
  },
  {
    vendor: 'fauna',
    name: 'fauna',
    label: 'Fauna',
    docs_url: 'https://clerk.com/docs/integration/fauna',
    claims: {
      aud: 'https://db.fauna.com/db/<YOUR_FAUNA_DB_ID>',
    },
    custom_signing_key: false,
    signing_algorithm: JwtSigningAlgorithm.RS256,
    enabled: true,
  },
  {
    vendor: 'grafbase',
    name: 'grafbase',
    label: 'Grafbase',
    docs_url: 'https://clerk.com/docs/integration/grafbase',
    claims: {
      groups: [],
    },
    custom_signing_key: false,
    signing_algorithm: JwtSigningAlgorithm.RS256,
    enabled: true,
  },
  {
    vendor: 'nhost',
    name: 'nhost',
    label: 'Nhost',
    docs_url: 'https://clerk.com/docs/integration/nhost',
    claims: {
      'https://hasura.io/jwt/claims': {
        'x-hasura-allowed-roles': ['me', 'user'],
        'x-hasura-default-role': 'user',
        'x-hasura-user-id': '{{user.id}}',
      },
    },
    custom_signing_key: false,
    signing_algorithm: JwtSigningAlgorithm.RS256,
    enabled: true,
  },
  {
    vendor: 'convex',
    name: 'convex',
    label: 'Convex',
    docs_url: 'https://clerk.com/docs/integration/convex',
    claims: {
      aud: 'convex',
      name: '{{user.full_name}}',
      nickname: '{{user.username}}',
      picture: '{{user.profile_image_url}}',
      given_name: '{{user.first_name}}',
      family_name: '{{user.last_name}}',
      email: '{{user.primary_email_address}}',
      phone_number: '{{user.primary_phone_number}}',
      email_verified: '{{user.email_verified}}',
      phone_number_verified: '{{user.phone_number_verified}}',
      updated_at: '{{user.updated_at}}',
    },
    custom_signing_key: false,
    signing_algorithm: JwtSigningAlgorithm.RS256,
    enabled: true,
  },
  {
    vendor: 'mongodb',
    name: 'mongodb',
    label: 'MongoDB',
    docs_url: 'https://docs.mongodb.com/realm/authentication/custom-jwt',
    claims: {
      aud: 'YourRealmAppId',
      user_data: {
        email: '{{user.primary_email_address}}',
        first_name: '{{user.first_name}}',
        last_name: '{{user.last_name}}',
      },
    },
    custom_signing_key: true,
    signing_algorithm: JwtSigningAlgorithm.RS256,
    enabled: false,
  },
  {
    vendor: 'announcekit',
    name: 'announcekit',
    label: 'AnnounceKit',
    docs_url: 'https://announcekit.app/docs/json-web-tokens',
    claims: {
      id: '{{user.id}}',
      email: '{{user.primary_email_address}}',
      name: '{{user.first_name}} {{user.last_name}}',
    },
    custom_signing_key: true,
    signing_algorithm: JwtSigningAlgorithm.HS256,
    enabled: false,
  },
  {
    vendor: 'grafana',
    name: 'grafana',
    label: 'Grafana',
    docs_url: 'https://grafana.com/docs/grafana/latest/auth/jwt/',
    claims: {
      'your-custom-claim': 'optional',
    },
    custom_signing_key: true,
    signing_algorithm: JwtSigningAlgorithm.RS256,
    enabled: false,
  },
];
