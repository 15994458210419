import { useLocation } from '@hooks';
import { useSession, useOrganization } from '@clerk/nextjs';
import { makeOrganizationsRootPath } from '@components/organizations/utils';
import { buildCacheKey } from '@utils/api';

import { PAGE_SIZE } from '@components/organizations';

import React from 'react';

const useOrganizationsPathAndKeys = (): {
  cacheKey: string;
  path: string;
  pathWithParams: string;
  makeInfinitePath: (
    search: string,
    userID: string,
  ) => (pageIndex: number, previousPageData: any) => string;
} => {
  const { instanceId, query } = useLocation();
  const { session } = useSession();
  const { organization } = useOrganization();

  const path = makeOrganizationsRootPath(instanceId);

  const search = (query?.query as string) || '';
  const page = parseInt(query.page as string, 10) || 1;

  const params = new URLSearchParams({
    query: search,
    offset: PAGE_SIZE * (page - 1) + '',
  });

  const pathWithParams = `${path}?${params.toString()}`;

  const cacheKey = buildCacheKey({
    path: pathWithParams,
    session,
    id: organization?.id,
  });

  const makeInfinitePath = React.useCallback(
    (search: string, userID: string) =>
      (pageIndex: number): string => {
        const params = new URLSearchParams({
          query: search,
          ...(userID ? { user_id: `-${userID}` } : {}),
          offset: PAGE_SIZE * pageIndex + '',
        });
        return `${path}?${params.toString()}`;
      },
    [path],
  );

  return { cacheKey, pathWithParams, path, makeInfinitePath };
};

export default useOrganizationsPathAndKeys;
