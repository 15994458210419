import React from 'react';

import { UsersTable } from '@components/users/Table/UsersTable';
import RowActions from './Actions/RowActions';

import useTable from './useTable';

import { OrganizationMembershipFLatUserData } from '@components/organizations/types';

import { PAGE_SIZE } from '@components/organizations/constants';

import { Center, Spinner } from '@chakra-ui/react';

const Table = (): JSX.Element => {
  const {
    data,
    schema,
    onRowClicked,
    page,
    isLoading,
    totalCount,
    onPaginate,
  } = useTable();

  return (
    <>
      {isLoading ? (
        <Center w='full' h='full'>
          <Spinner />
        </Center>
      ) : (
        <UsersTable<OrganizationMembershipFLatUserData>
          users={data}
          tableSchema={schema}
          onRowClicked={onRowClicked}
          rowActions={(member: OrganizationMembershipFLatUserData) => (
            <RowActions member={member} />
          )}
          perPage={PAGE_SIZE}
          page={page}
          totalCount={totalCount}
          onPaginate={onPaginate}
        />
      )}
    </>
  );
};

export default Table;
