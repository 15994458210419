import React from 'react';
import { Avatar, Flex, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import type { User } from './RecentActivity';

type UserListProps = {
  users: User[];
  action: 'sign-in' | 'sign-up';
};

export function UserList({ users, action }: UserListProps): JSX.Element {
  return (
    <>
      {users.map(
        ({
          first_name,
          last_name,
          identifier,
          time,
          profile_image_url,
          user_id,
        }) => (
          <Flex
            key={action + user_id}
            py='2.5'
            justify='space-between'
            align='center'
          >
            <Flex>
              <Avatar h='36px' w='36px' src={profile_image_url} mr='2' />

              <Flex direction='column' justify='center'>
                <Text textStyle='sm-medium' color='black'>
                  {first_name} {last_name}
                </Text>
                {identifier && (
                  <Text textStyle='sm-normal' color='gray.500'>
                    {identifier}
                  </Text>
                )}
              </Flex>
            </Flex>

            <Text textStyle='sm-normal' color='gray.500'>
              {time && format(new Date(time), 'ccc MMM d, HH:mm')}
            </Text>
          </Flex>
        ),
      )}
    </>
  );
}
