import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link as ChakraLink,
  Radio,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';
import Link from 'next/link';
import { useForm, useWatch } from 'react-hook-form';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { InformationBox } from '../../common';
import { GoogleAnalyticsIntegrationAttributes } from '../types';
import { removeAutoComplete } from '@utils';
import { RadioGroup, Modal } from '@components/common';

const FormFields = {
  integrationFormId: 'google_analytics_integration',
  googleAnalyticsType: 'google_analytics_type',
  integrationTypes: [
    { name: 'Google Analytics 4', value: 'google_analytics_v4' },
    { name: 'Universal Analytics', value: 'universal' },
  ],
  events: [
    { label: 'Sign up', value: 'sign_up' },
    { label: 'Sign in', value: 'login' },
  ],
  trackingId: 'tracking_id',
  measurementId: 'measurement_id',
  includeUserId: 'include_user_id',
  apiSecret: 'api_secret',
} as const;

interface GoogleAnalyticsModalProps {
  isOpen: boolean;
  onClose: () => void;
  existingSettings: Partial<GoogleAnalyticsIntegrationAttributes> | undefined;
  onSave: (values: GoogleAnalyticsIntegrationAttributes) => Promise<void>;
}

export function GoogleAnalyticsModal({
  isOpen,
  onClose,
  existingSettings,
  onSave,
}: GoogleAnalyticsModalProps): JSX.Element {
  const [submitError, setSubmitError] = React.useState<string | null>(null);

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    reset,
    control,
    register,
  } = useForm();

  const selectedIntegrationType: typeof FormFields.integrationTypes[number]['value'] =
    useWatch({
      control,
      name: FormFields.googleAnalyticsType,
    });

  React.useEffect(() => {
    if (!existingSettings) {
      reset({
        [FormFields.googleAnalyticsType]: FormFields.integrationTypes[0].value,
      });
      return;
    }

    const { events, ...rest } = existingSettings;

    reset({
      ...rest,
      sign_up: !events.includes('sign_up'),
      login: !events.includes('login'),
    });
  }, [existingSettings, reset]);

  const updateSettings = async (formData): Promise<void> => {
    submitError && setSubmitError(null);

    const events = FormFields.events.reduce(
      (acc, { value }) => (formData[value] ? acc : [...acc, value]),
      [],
    );

    const settings: GoogleAnalyticsIntegrationAttributes = {
      events,
      enabled: true,
      google_analytics_type: formData[FormFields.googleAnalyticsType],
      api_secret: formData[FormFields.apiSecret] || '',
      measurement_id: formData[FormFields.measurementId] || '',
      tracking_id: formData[FormFields.trackingId] || '',
      include_user_id: formData[FormFields.includeUserId],
    };

    try {
      await onSave(settings);
      onClose();
    } catch (error) {
      setSubmitError('Something went wrong, please try again');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
      size='xl'
      title='Google Analytics'
      subtitle='Configure integration'
    >
      <Modal.Body>
        <VStack
          as='form'
          id={FormFields.integrationFormId}
          onSubmit={handleSubmit(updateSettings)}
          align='stretch'
          spacing='6'
        >
          <InformationBox icon={InformationCircleIcon}>
            <Text textStyle='md-normal' display='inline-block' color='gray.500'>
              We’ve written great documentation on how to integrate Google
              Analytics with Clerk.{' '}
              <Link
                href='https://clerk.com/docs/integration/google-analytics'
                passHref
              >
                <ChakraLink
                  textTransform='unset'
                  color='primary.500'
                  fontWeight='medium'
                >
                  Learn more about Google Analytics integration.
                </ChakraLink>
              </Link>
            </Text>
          </InformationBox>

          <Box>
            <Text textStyle='md-medium' mb='0.5'>
              Integration type
            </Text>
            <Text textStyle='sm-normal' mb='2' color='gray.500'>
              Select which Google integration to use
            </Text>

            <RadioGroup
              control={control}
              name={FormFields.googleAnalyticsType}
              rules={{ required: true }}
              spacing={3}
              align='flex-start'
              mt={4}
            >
              {FormFields.integrationTypes.map(i => (
                <Radio key={i.name} size='lg' value={i.value}>
                  <Text textStyle='sm-normal' mt='0.5'>
                    {i.name}
                  </Text>
                </Radio>
              ))}
            </RadioGroup>
          </Box>

          <Divider />

          {selectedIntegrationType === 'google_analytics_v4' && (
            <>
              <Box>
                <FormControl
                  isInvalid={!!errors[FormFields.apiSecret]}
                  {...removeAutoComplete(FormFields.apiSecret)}
                >
                  <FormLabel fontSize='sm' mb='0.5'>
                    API secret
                  </FormLabel>
                  <Text textStyle='sm-normal' mb='2' color='gray.500'>
                    Enter the API secret value from{' '}
                    <Text as='span' fontWeight='medium'>
                      Google Analytics › Admin › Property Column › Data Streams
                      › [Data Stream] › Measurement Protocol API secrets
                    </Text>
                  </Text>
                  <Input
                    {...register(FormFields.apiSecret, {
                      required: 'Required',
                    })}
                    autoFocus
                  />
                  <FormErrorMessage>
                    {errors[FormFields.apiSecret]?.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>

              <Box>
                <FormControl
                  isInvalid={!!errors[FormFields.measurementId]}
                  {...removeAutoComplete(FormFields.measurementId)}
                >
                  <FormLabel fontSize='sm' mb='0.5'>
                    Measurement ID
                  </FormLabel>
                  <Text textStyle='sm-normal' mb='2' color='gray.500'>
                    Enter the Measurement ID from{' '}
                    <Text as='span' fontWeight='medium'>
                      Google Analytics › Admin › Property Column › Data Streams
                      › [Data Stream]{' '}
                    </Text>
                    details
                  </Text>
                  <Input
                    {...register(FormFields.measurementId, {
                      required: 'Required',
                    })}
                  />
                  <FormErrorMessage>
                    {errors[FormFields.measurementId]?.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>
            </>
          )}

          {selectedIntegrationType === 'universal' && (
            <Box>
              <FormControl
                isInvalid={!!errors[FormFields.trackingId]}
                {...removeAutoComplete(FormFields.trackingId)}
              >
                <FormLabel fontSize='sm' mb='0.5'>
                  Tracking ID
                </FormLabel>
                <Text textStyle='sm-normal' mb='2'>
                  Enter the API secret value from{' '}
                  <Text as='span' fontWeight='medium'>
                    Google Analytics › Admin › Property Column › Property
                    Settings › Measurement Protocol API secrets
                  </Text>
                </Text>
                <Input
                  {...register(FormFields.trackingId, {
                    required: 'Required',
                  })}
                  autoFocus
                />
                <FormErrorMessage>
                  {errors[FormFields.trackingId]?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
          )}

          <Box>
            <Flex justify='space-between'>
              <Text textStyle='md-medium' mb='0.5'>
                Send UserId parameter with Clerk UID
              </Text>

              <Switch
                data-testid='switch-clerk-user-id'
                {...register(FormFields.includeUserId)}
              />
            </Flex>
            <Text textStyle='sm-normal' color='gray.500'>
              Include Clerk User ID in the GA event payload
            </Text>
          </Box>

          <Box>
            <Text textStyle='md-medium' mb='0.5'>
              Exclude referral events
            </Text>

            <Text textStyle='sm-normal' mb='2' color='gray.500'>
              By default, a referral automatically triggers a new session.
              Exclude referral events to prevent triggering new sessions in
              analytics.
            </Text>

            {FormFields.events.map(({ label, value }) => (
              <Flex key={value} py='2' px='4'>
                <Checkbox
                  data-testid={`checkbox-${value}`}
                  {...register(value)}
                >
                  Exclude “{label} complete”
                </Checkbox>
              </Flex>
            ))}
          </Box>
        </VStack>
      </Modal.Body>

      <Modal.Footer mt='10'>
        <Flex direction='column' w='full'>
          <Flex justify='space-between' w='full' flexDir='row-reverse'>
            <Button
              form={FormFields.integrationFormId}
              type='submit'
              isLoading={isSubmitting}
            >
              Apply changes
            </Button>

            <Button type='button' variant='ghost' onClick={onClose}>
              Cancel
            </Button>
          </Flex>

          {submitError && (
            <Text
              textStyle='sm-normal'
              color='danger.500'
              textAlign='right'
              mt='2'
            >
              {submitError}
            </Text>
          )}
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}
