import React from 'react';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Input,
  Text,
  VStack,
  ThemeTypings,
  ModalContentProps,
  Icon,
  ButtonProps,
} from '@chakra-ui/react';

import { InputBox } from '@components/common';
import WarningPanel from './WarningPanel';

export type ConfirmationModalProps = {
  isConfirmationLoading?: boolean;
  isOpen: boolean;
  headerTitle: string;
  message: string | React.ReactElement;
  cancelationMessage: string;
  confirmationMessage: string;
  verification?: {
    text?: string | React.ReactElement;
    placeholder?: string;
    stringToMatch: string;
    label: string | JSX.Element;
  };
  closeOnOverlayClick?: boolean;
  handleConfirmation: () => void;
  handleCancelation: () => void;
  colorScheme?: ThemeTypings['colorSchemes'];
  warningMessage?: React.ReactNode;
  alertDialogProps?: ModalContentProps;
  confirmButtonIcon?: (props: React.ComponentProps<'svg'>) => JSX.Element;
  confirmationButtonProps?: ButtonProps;
  disableConfirmation?: boolean;
};

const CustomElementOrText = ({ children }) => {
  return React.isValidElement(children) ? (
    children
  ) : (
    <Text textStyle='md-normal'> {children}</Text>
  );
};

const ConfirmationModal = ({
  isOpen,
  headerTitle,
  message,
  cancelationMessage,
  confirmationMessage,
  verification,
  closeOnOverlayClick = false,
  isConfirmationLoading,
  handleConfirmation,
  handleCancelation,
  colorScheme = 'red',
  warningMessage,
  alertDialogProps,
  confirmButtonIcon,
  confirmationButtonProps,
  disableConfirmation,
}: ConfirmationModalProps): JSX.Element => {
  const cancelRef = React.useRef();
  const [verificationValue, setVerificationValue] = React.useState('');

  const isConfirmationDisabled = verification
    ? verificationValue !== verification.stringToMatch
    : false;

  const clearVerificationValue = () => {
    setVerificationValue('');
  };
  const onCancelation = () => {
    handleCancelation();
    clearVerificationValue();
  };

  const onConfirmation = () => {
    handleConfirmation();
    clearVerificationValue();
  };

  return (
    <AlertDialog
      closeOnOverlayClick={closeOnOverlayClick}
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onCancelation}
    >
      <AlertDialogOverlay>
        <AlertDialogContent {...alertDialogProps}>
          <AlertDialogHeader mb='6' fontSize='xl' fontWeight='bold'>
            {headerTitle}
          </AlertDialogHeader>

          <AlertDialogBody>
            <VStack alignItems='start'>
              <CustomElementOrText>{message}</CustomElementOrText>
              {warningMessage && <WarningPanel message={warningMessage} />}

              {verification && (
                <>
                  {verification.text && (
                    <CustomElementOrText>
                      {verification.text}
                    </CustomElementOrText>
                  )}
                  <InputBox width='100%' p={0} label={verification.label}>
                    <Input
                      placeholder={verification?.placeholder}
                      value={verificationValue}
                      onChange={e => setVerificationValue(e.target.value)}
                    />
                  </InputBox>
                </>
              )}
            </VStack>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              variant='ghost'
              fontSize='xs'
              fontWeight='bold'
              ref={cancelRef}
              onClick={onCancelation}
            >
              {cancelationMessage}
            </Button>
            <Button
              leftIcon={
                confirmButtonIcon ? <Icon as={confirmButtonIcon} /> : null
              }
              colorScheme={colorScheme}
              fontSize='xs'
              fontWeight='bold'
              disabled={
                isConfirmationDisabled ||
                isConfirmationLoading ||
                disableConfirmation
              }
              onClick={onConfirmation}
              ml={3}
              isLoading={isConfirmationLoading}
              {...confirmationButtonProps}
            >
              {confirmationMessage}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default ConfirmationModal;
