import React from 'react';
import { useRouter } from 'next/router';
import { Box, HStack, Text, Tr, Center, Spinner } from '@chakra-ui/react';
import { DumbPagination } from '@components/common';
import { EmptyTable, TableWrapper, Td } from './TableParts';
import { OrganizationsAvatar } from './OrganizationAvatar';
import { formatRel } from '@components/users/utils';
import { useLocation } from '@hooks';

import { useGetOrganizationsFromDAPI } from '@components/organizations/core';

const PAGE_SIZE = 10;

export type OrganizationTableProps = {
  instanceId: string;
};

export function OrganizationTable({
  instanceId,
}: OrganizationTableProps): JSX.Element {
  const { applicationId } = useLocation();

  const router = useRouter();
  const page = parseInt(router.query.page as string, 10) || 1;

  const {
    data: payload,
    error: organizationsError,
    totalCount: orgsCount,
    hasLoaded,
  } = useGetOrganizationsFromDAPI();

  const hasNextPage = React.useMemo(() => {
    if (!orgsCount) {
      return false;
    }

    return orgsCount > PAGE_SIZE * page;
  }, [page, orgsCount]);

  const navigateToOrganizationDetails = (id: string) => {
    void router.push({
      pathname: `/apps/${applicationId}/instances/${instanceId}/organizations/${id}`,
      query: {
        page,
      },
    });
  };

  if (organizationsError) {
    return (
      <Text textStyle='sm-normal' textAlign='center' color='blackAlpha.700'>
        Something went wrong while fetching organizations.
      </Text>
    );
  }

  if (!hasLoaded) {
    return (
      <Center w='full' h='full'>
        <Spinner aria-label='Loading organizations..' />
      </Center>
    );
  }

  if (!payload.length) {
    return <EmptyTable />;
  }

  return (
    <>
      <TableWrapper>
        {payload.map((org, index) => {
          const isLast = index === payload.length - 1;

          return (
            <Tr
              onClick={() => {
                navigateToOrganizationDetails(org.id);
              }}
              key={org.id}
              overflow='hidden'
              _hover={{
                bg: 'gray.50',
                cursor: 'pointer',
              }}
            >
              <Td
                borderTopLeftRadius='md'
                borderBottomLeftRadius='md'
                withBorder={isLast}
                px={4}
                py={2}
              >
                <HStack>
                  <OrganizationsAvatar organizationsLogo={org.logo_url} />
                  <Box maxW='40ch'>
                    <Text textStyle='sm-medium' noOfLines={1}>
                      {org.name}
                    </Text>
                  </Box>
                </HStack>
              </Td>
              <Td textStyle='sm-normal' color='gray.500' withBorder={isLast}>
                {org.id}
              </Td>
              <Td textStyle='sm-normal' color='gray.500' withBorder={isLast}>
                {org?.members_count?.toLocaleString()}
              </Td>
              <Td textStyle='sm-normal' color='gray.500' withBorder={isLast}>
                {formatRel(org.created_at)}
              </Td>
            </Tr>
          );
        })}
      </TableWrapper>

      <DumbPagination
        currentPage={page}
        itemsThisPage={payload.length}
        hasNextPage={hasNextPage}
      />
    </>
  );
}
