import React from 'react';
import { Image, ImageProps } from '@chakra-ui/image';
import { User } from '../../types';
import { Box, BoxProps } from '@chakra-ui/react';
import { formatName, getInitials } from '@components/users/utils';

const CLERK_DEFAULT_AVATAR =
  'https://images.clerk.services/clerk/default-profile.svg';
const GRAVATAR_DEFAULT_AVATAR = 'https://www.gravatar.com/avatar?d=mp';

export type UserAvatarProps = ImageProps & {
  user: Pick<User, 'first_name' | 'last_name' | 'profile_image_url'>;
};

export function UserAvatar({
  user,
  ...imageProps
}: UserAvatarProps): JSX.Element {
  imageProps.borderRadius ||= '50%';
  if (isUserAvatar(user.profile_image_url)) {
    return <Image {...imageProps} src={user.profile_image_url} />;
  }
  const initials = getInitials(user);
  if (initials) {
    const fullName = formatName(user);
    const boxProps = {
      boxSize: imageProps.boxSize,
      borderRadius: imageProps.borderRadius,
      title: fullName,
    };

    return (
      <InitialsFallback initials={initials} fullName={fullName} {...boxProps} />
    );
  }
  return <Image {...imageProps} src={GRAVATAR_DEFAULT_AVATAR} />;
}

type InitialsFallbackProps = BoxProps & {
  fullName: string;
  initials: string;
};

const InitialsFallback = ({
  fullName,
  initials,
  ...boxProps
}: InitialsFallbackProps): JSX.Element => {
  return (
    <Box {...boxProps}>
      <svg
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0, 0, 100, 100'
        aria-label={fullName}
      >
        <title>{fullName}</title>
        <circle cx='50' cy='50' r='49' fill='currentColor' strokeWidth='0' />
        <text
          x='50'
          y='71.5'
          fontFamily='inherit'
          fontSize='60'
          fontWeight='400'
          textAnchor='middle'
          fill='white'
        >
          {initials}
        </text>
      </svg>
    </Box>
  );
};

const isUserAvatar = (url: string): boolean => {
  return (
    url !== undefined &&
    url !== CLERK_DEFAULT_AVATAR &&
    url !== GRAVATAR_DEFAULT_AVATAR
  );
};
