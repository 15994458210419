import React from 'react';

import type { OAuthProviderSettings } from '@types';

import { SwitchInfoField } from '../SwitchInfoField';
import { HStack, Img, Text, Center, Spinner } from '@chakra-ui/react';
import { GenericBadge as Badge } from '@components/common';

import { svgUrl } from '@utils';
import { AUTHENTICATABLE_LABEL } from '../constants';

const ProviderName = ({ logo, name }) => (
  <HStack as='span' mb='2'>
    <Img src={logo} />
    <Text textStyle='md-medium' mb='0.5'>
      {name}
    </Text>
  </HStack>
);

const getBadges = (settings: OAuthProviderSettings): JSX.Element[] => {
  const { enabled, authenticatable } = settings;
  if (!enabled) {
    return [];
  }

  const badges = [];

  authenticatable && badges.push(<Badge label={AUTHENTICATABLE_LABEL} />);

  return badges;
};

type SocialProvidersFieldsProps = {
  providers: OAuthProviderSettings[];
  handleSwitchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCogClick: (providerName: string) => void;
};

const SocialProvidersFields = ({
  providers,
  handleSwitchChange,
  onCogClick,
}: SocialProvidersFieldsProps): JSX.Element => {
  return (
    <>
      {!providers.length ? (
        <Center h='430px' w='full'>
          <Spinner />
        </Center>
      ) : (
        providers.map(providerSettings => (
          <SwitchInfoField
            key={providerSettings.strategy}
            name={providerSettings.strategy}
            accessKey={`social.${providerSettings.strategy}.enabled`}
            title={
              <ProviderName
                logo={svgUrl(providerSettings.provider)}
                name={providerSettings.name}
              />
            }
            description={`Users can connect their ${providerSettings.name} account`}
            docsUrl={providerSettings.docsUrl}
            isDisabled={false}
            badges={getBadges(providerSettings)}
            handleSwitchChange={handleSwitchChange}
            onCogClick={onCogClick}
          />
        ))
      )}
    </>
  );
};

export default SocialProvidersFields;
